import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { GraphIcon, PeopleIcon } from '@primer/octicons-react';
import { createStyles } from '@material-ui/styles';
import NavbarItem, { INavbarItem } from '../NavbarItem';

interface INavbarProps {
  position: string,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.primary.light,
    boxShadow: theme.shadows[8],
    position: 'fixed',
    right: 0,
    bottom: 10,
    height: 60,
    margin: '0 30px',
    left: 0,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    justifyContent: 'space-around',
    backdropFilter: 'blur(10px)',
  },
}));

const Navbar:React.FC<INavbarProps> = ({ position }:INavbarProps) => {
  const links:Array<INavbarItem> = [
    {
      icon: <GraphIcon size={24} />,
      text: 'Dashboard',
      path: '/',
    },
    {
      icon: <PeopleIcon size={24} />,
      text: 'Sessions',
      path: '/sessions',
    },
    {
      icon: <GraphIcon size={24} />,
      text: 'Dashboard',
      path: '/d',
    },
    {
      icon: <PeopleIcon size={24} />,
      text: 'Sessions',
      path: '/as',
    },
    {
      icon: <GraphIcon size={24} />,
      text: 'Dashboard',
      path: '/dd',
    },
    {
      icon: <PeopleIcon size={24} />,
      text: 'Sessions',
      path: '/aas',
    },
  ];
  const classes = useStyles(position);
  return (
    <div className={classes.root}>
      {
        links.map((el:INavbarItem) => (
          <NavbarItem icon={el.icon} text={el.text} path={el.path} />))
      }
    </div>
  );
};

export default Navbar;
