import React, { useContext } from 'react';
import { Slide, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { AuthContext } from '../../contexts/AuthContext';

interface IDefaultPageProps {
  children: React.ReactNode,
}
const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: 20,
    margin: 10,
    background: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
}));

const DefaultPage:React.FC<IDefaultPageProps> = (props:IDefaultPageProps) => {
  const { children } = props;
  const { isReady } = useContext(AuthContext);
  const classes = useStyles();
  return (
    <Slide direction="right" mountOnEnter unmountOnExit in={isReady}>
      <Grid container>
        <Grid item xs>
          <Paper className={classes.paper}>
            <div>
              {children}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default DefaultPage;
