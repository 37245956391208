import React from 'react';
import { UnlockIcon } from '@primer/octicons-react';
import { Box, Collapse, TextField } from '@material-ui/core';

interface IAuthFormProps {
  onChange: (name:string, e:React.ChangeEvent<{ value: unknown }>) => void;
  show: boolean;
  inputData: any;
  classes: any;
}

const AuthForm:(
  {
    show, onChange, inputData, classes,
  }: IAuthFormProps) => JSX.Element = (
    {
      show, onChange, inputData, classes,
    }:IAuthFormProps,
  ): JSX.Element => (
    <Box display="flex" flexDirection="column">
      <Collapse timeout={{ enter: 450, exit: 100 }} in={show} mountOnEnter unmountOnExit>
        <div style={{ padding: 30, textAlign: 'center' }}>
          <UnlockIcon size={50} />
        </div>
      </Collapse>
      <Collapse timeout={{ enter: 450, exit: 200 }} in={show} mountOnEnter unmountOnExit>
        <div style={{ display: 'flex', flexFlow: 'column' }}>
          <TextField
            id="outlined-login-input"
            classes={classes}
            onChange={(e) => onChange('login', e)}
            value={inputData.login}
            size="small"
            label="Login"
            type="text"
            variant="outlined"
          />
          <TextField
            id="outlined-password-input"
            classes={classes}
            onChange={(e) => onChange('password', e)}
            value={inputData.password}
            size="small"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
          />
        </div>
      </Collapse>
    </Box>
  );

export default AuthForm;
