import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createStyles, ThemeProvider } from '@material-ui/styles';
import {
  CircularProgress, Container, CssBaseline, Zoom,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import axios from 'axios';
import { io, Socket } from 'socket.io-client';
import { AuthContext } from './contexts/AuthContext';
import TradesPage from './pages/TradesPage';
import Navbar from './components/Navbar';
import themeMap from './theme';
import SessionsPage from './pages/SessionsPage';
import useAuth from './hooks/AuthHook';
import AuthPage from './pages/AuthPage';

const Axios = axios.create({ withCredentials: true });
const socketIo = io(`//${window.location.hostname}`);
const useStyles = makeStyles((theme: Theme) => (createStyles({
  root: {
    background: theme.palette.background.paper,
  },
})));

const App:React.FC = () => {
  const [theme, setTheme] = useState(themeMap.dark);
  useEffect(() => {
    socketIo.on('test', (socket) => {
      alert(socket);
    });
  }, []);
  const {
    user,
    login,
    logout,
    isReady,
  } = useAuth();
  const auth = useContext(AuthContext);
  const logins = async () => {
    const query = await Axios.post(`${process.env.REACT_APP_DOMAIN}/auth/auth`, {
      login: 'login',
      password: 'pswd',
    });
  };
  const classes = useStyles();
  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}>
        <AuthContext.Provider value={{
          user,
          login,
          logout,
          isReady,
        }}
        >
          <ThemeProvider theme={theme}>
            <button type="button" onClick={() => setTheme(theme.palette.type === 'dark' ? themeMap.light : themeMap.dark)}>Set theme</button>
            <Container maxWidth={false}>
              <CssBaseline />
              {
                !isReady
                  ? (
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh',
                      width: '100%',
                    }}
                    >
                      <CircularProgress />
                    </div>
                  )
                  : (
                    <>
                      {!user.isAuthenticated || !user.isActive
                        ? (
                          <Zoom in={isReady}>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100vh',
                              width: '100%',
                            }}
                            >
                              <AuthPage />
                            </div>
                          </Zoom>
                        )
                        : (
                          <BrowserRouter>
                            <button type="button" onClick={logins}>Login</button>
                            <strong>{user.login}</strong>
                            <Switch>
                              <Route path="/" exact component={TradesPage} />
                              <Route path="/sessions" component={SessionsPage} />
                            </Switch>
                            <Navbar position="fixed" />
                            <div style={{ height: '60px', margin: 10 }}> </div>
                          </BrowserRouter>
                        )}
                    </>
                  )
              }
            </Container>
          </ThemeProvider>
        </AuthContext.Provider>
      </SnackbarProvider>
    </div>
  );
};

export default App;
