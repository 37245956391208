import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IPageTitle {
  children: React.ReactNode,
  title: string,
}

const searchBlock = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.primary.light,
    padding: 20,
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    position: 'initial',
  },
  fixed: {
    top: 0,
    zIndex: 999,
    padding: 10,
    position: 'sticky',
    fontSize: 13,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    animation: '$fixedHead 500ms',
  },
  '@keyframes fixedHead': {
    '0%': {
      padding: 20,
    },
    '100%': {
      padding: 10,
    },
  },
}));

const PageTitle:React.FC<IPageTitle> = (props:IPageTitle) => {
  const { title, children } = props;
  const classes = searchBlock();
  const [searchPosition, setSearchPosition] = useState<boolean>(false);
  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setSearchPosition(true);
    } else {
      setSearchPosition(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [null]);

  return (
    <div className={clsx(classes.root, searchPosition && classes.fixed)}>
      <span>
        <strong>{title}</strong>
      </span>
      {children}
    </div>
  );
};
export default PageTitle;
