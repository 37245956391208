import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TelegramLoginButton from 'react-telegram-login';
import { AuthContext } from '../../contexts/AuthContext';
import AuthForm from '../../components/AuthForm';
import ConfirmForm from '../../components/ConfirmForm';
import RegisterForm from '../../components/RegisterForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    transition: '1s',
    fontSize: 24,
  },
  disable: {
    color: theme.palette.text.disabled,
    transition: '1s',
    fontSize: 24,
  },
}));
const useStylesInput = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      margin: theme.spacing(1),
    },
  })));

interface IUserData {
  login: string;
  password: string;
  email?: string;
  type?: string;
  employerId?: string;
}

const AuthPage:React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, login } = useContext(AuthContext);
  const [select, setSelect] = useState<string>('login');
  const classes = useStyles();
  const inputClasses = useStylesInput();
  const [userData, setUserData] = useState<IUserData>({
    login: '',
    password: '',
    email: '',
    type: '',
    employerId: '',
  });
  const submitForm = () => {
    if (select === 'login') {
      login(userData).then((value:any) => {
        enqueueSnackbar(value.message, { variant: value.success ? 'success' : 'error' });
      });
    } else if (select === 'signup') {
      console.log('43');
    }
  };
  const changeSelect = (type: string) => {
    setSelect(type);
  };
  const handleChange = (name: string, event: React.ChangeEvent<{ value: unknown }>) => {
    setUserData((prevState:IUserData) => ({ ...prevState, [name]: event.target.value }));
  };
  return (
    <Box component="div" width="300" height={500} display="flex" alignItems="flex-start" flexDirection="row" flexWrap="wrap" justifyContent="center">
      <div style={{
        display: 'flex', justifyContent: 'space-between', width: '300px', alignSelf: 'flex-start',
      }}
      >
        <Button onClick={() => changeSelect('login')}><strong className={select === 'login' ? classes.root : classes.disable}>Login</strong></Button>
        <Button onClick={() => changeSelect('signup')}><strong className={select === 'signup' ? classes.root : classes.disable}>Sign Up</strong></Button>
      </div>
      <div style={{
        display: 'flex', justifyContent: 'center', overflow: 'hidden', width: '100%',
      }}
      >
        {!user.isAuthenticated && (
          <div style={{
            display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <AuthForm onChange={handleChange} show={select === 'login'} inputData={userData} key={1} classes={inputClasses} />
            <RegisterForm onChange={handleChange} show={select === 'signup'} inputData={userData} classes={inputClasses} />
            {select === 'login' && <Button className={inputClasses.root}>Forgot Password</Button>}
            <Button onClick={submitForm} variant="outlined" type="button">{select === 'login' ? 'Enter' : 'Register'}</Button>
            <div style={{
              display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', margin: 10, alignItems: 'center',
            }}
            >
              <Divider variant="middle" style={{ width: '100%' }} />
              <strong>OR</strong>
              <Divider variant="middle" style={{ width: '100%' }} />
            </div>
            <TelegramLoginButton dataUserpic={false} dataAuthUrl={`${process.env.REACT_APP_DOMAIN}/auth/tg`} botName={process.env.REACT_APP_BOT_NAME} />
          </div>
        )}
        {user.isAuthenticated && !user.isActive && (
          <ConfirmForm
            onChange={handleChange}
            inputData={userData}
            user={user}
            classes={inputClasses}
          />
        )}
      </div>
    </Box>
  );
};

export default AuthPage;
