import React from 'react';
import { PersonAddIcon } from '@primer/octicons-react';
import {
  Avatar,
  Box,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Zoom,
} from '@material-ui/core';
import { IUser } from '../../contexts/AuthContext';

interface IConfirmFormProps {
  onChange: (name:string, e:React.ChangeEvent<{ value: unknown }>) => void;
  inputData: any;
  user: IUser;
  classes: any;
}

const ConfirmForm:(
  {
    onChange, inputData, classes, user,
  }: IConfirmFormProps) => JSX.Element = (
    {
      onChange, inputData, classes, user,
    }:IConfirmFormProps,
  ): JSX.Element => (
    <Box display="flex" flexDirection="column">
      <Collapse
        timeout={{ enter: 450, exit: 100 }}
        in={!user.isActive && user.isAuthenticated}
        mountOnEnter
        unmountOnExit
      >
        <div style={{
          padding: 30, textAlign: 'center', display: 'flex', justifyContent: 'center', flexFlow: 'column', alignItems: 'center',
        }}
        >
          <Avatar
            style={{ width: 100, height: 100, fontSize: 60 }}
            alt={user.login}
            src={user.avatar}
          />
          <Typography style={{ margin: 10 }} align="center" variant="h4" component="h4">
            {user.login}
          </Typography>
        </div>
      </Collapse>
      <Collapse in={!user.isActive && user.isAuthenticated} style={{ width: '100%' }}>
        <Zoom in={!user.isActive} style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
            <Collapse in={inputData.type === 'worker'}>
              <TextField
                id="outlined-emloyerId-input"
                classes={classes}
                onChange={(e) => onChange('employerId', e)}
                value={inputData.employerId}
                size="small"
                label="Employer ID"
                type="text"
                variant="outlined"
              />
            </Collapse>
            <FormControl size="small" variant="outlined" classes={classes} style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-outlined-label">Account type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={inputData.type}
                onChange={(e) => onChange('type', e)}
                label="Account type"
              >
                <MenuItem value="worker">Worker</MenuItem>
                <MenuItem value="employer">Employer</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Zoom>
      </Collapse>
    </Box>
  );

export default ConfirmForm;
