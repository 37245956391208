import { createTheme, Theme } from '@material-ui/core/styles';

const light:Theme = createTheme({
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#fffs',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: 'rgba(255, 2555, 255, 0.4)',
      main: '#232323',
      dark: '#1E1919',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5999FF',
      main: '#2370FF',
      dark: '#1956DB',
      contrastText: '#fff',
    },
    error: {
      light: '#FB7673',
      main: '#F94551',
      dark: '#D6324C',
      contrastText: '#fff',
    },
    success: {
      light: '#8ED853',
      main: '#5EBF22',
      dark: '#45A418',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  shape: {
    borderRadius: 26,
  },
});
const dark:Theme = createTheme({
  palette: {
    type: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#232323',
      default: '#232323',
    },
    primary: {
      light: 'rgba(29,29,29,0.4)',
      main: '#fff',
      dark: '#fff',
      contrastText: '#232323',
    },
    secondary: {
      light: '#5999FF',
      main: '#2370FF',
      dark: '#1956DB',
      contrastText: '#fff',
    },
    error: {
      light: '#FB7673',
      main: '#F94551',
      dark: '#D6324C',
      contrastText: '#fff',
    },
    success: {
      light: '#8ED853',
      main: '#5EBF22',
      dark: '#45A418',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: '#5a5a5a',
      hint: '#fff',
    },
  },
  shape: {
    borderRadius: 26,
  },
});

const themeMap:any = {
  dark, light,
};

export default themeMap;
