import React from 'react';
import {
  createStyles, makeStyles, Theme, withStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  Avatar,
  Badge, FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  OutlinedInput,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { BriefcaseIcon, IssueReopenedIcon, KebabHorizontalIcon } from '@primer/octicons-react';
import InventoryItem from '../InventoryItem';

interface ISession {
  steamId: string,
  ownerId: string,
  username: string,
  avatar: string,
  botId: string,
  minPrice: number,
  stealState: boolean,
  liveSession: boolean,
  availableItemsSum: number,
  holdItemsSum: number,
  createdAt: string,
  appIds: Array<any>,
  proxyIp: string,
}
const StyledBadge = (props: ISession) => (
  withStyles((theme: Theme) => (
    createStyles({
      badge: {
        backgroundColor:
          props.liveSession ? theme.palette.success.main : theme.palette.error.main,
        color: props.liveSession ? theme.palette.success.light : theme.palette.error.light,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          animation: '$ripple 1.2s infinite ease-in-out',
          border: '1px solid currentColor',
          content: '""',
        },
      },
      '@keyframes ripple': {
        '0%': {
          transform: 'scale(.8)',
          opacity: 1,
        },
        '100%': {
          transform: 'scale(2.4)',
          opacity: 0,
        },
      },
    })),
  { withTheme: true })(Badge)
);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const createData = (
  steamId: string,
  ownerId: string,
  username: string,
  avatar: string,
  botId: string,
  minPrice: number,
  stealState: boolean,
  liveSession: boolean,
  availableItemsSum: number,
  holdItemsSum: number,
  createdAt: string,
  appIds: [],
  proxyIp: string,
) => ({
  steamId,
  ownerId,
  username,
  avatar,
  botId,
  minPrice,
  stealState,
  liveSession,
  availableItemsSum,
  holdItemsSum,
  createdAt,
  appIds,
  proxyIp,
});
const useUserStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
));
const itemsStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});
function Row(props: { row: ISession }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const OnlineBadge = StyledBadge(row);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useRowStyles();
  const userdataClass = useUserStyles();
  const itemsClass = itemsStyles();
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          <div className={userdataClass.root}>
            <OnlineBadge overlap="circular" badgeContent=" " variant="dot">
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </OnlineBadge>
            <Link href={row.steamId}>
              {row.username}
              <br />
              <small>{row.steamId}</small>
            </Link>
          </div>
        </TableCell>
        <TableCell align="right">{row.ownerId}</TableCell>
        <TableCell align="right">{row.botId}</TableCell>
        <TableCell align="right">
          <FormControl size="small" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={row.minPrice}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              labelWidth={60}
              endAdornment={
                (
                  <InputAdornment position="end">
                    <IconButton edge="end" aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
                      {open ? <BriefcaseIcon /> : <BriefcaseIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </TableCell>
        <TableCell align="right">{row.createdAt}</TableCell>
        <TableCell align="right">
          <span>
            Available:
            <strong>
              $
              {row.availableItemsSum}
            </strong>
          </span>
          <br />
          <span>
            Hold:
            <strong>
              $
              {row.holdItemsSum}
            </strong>
          </span>
        </TableCell>
        <TableCell align="right">
          {
            row.liveSession
              ? (
                <>
                  <Tooltip title="Pause steal">
                    <FormControlLabel
                      control={<Switch size="small" />}
                      label=""
                    />
                  </Tooltip>
                  <Tooltip title="More actions">
                    <IconButton onClick={handleClick} aria-label="expand row" size="medium">
                      <KebabHorizontalIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </Menu>
                  <Tooltip title="Inventory">
                    <IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
                      {open ? <BriefcaseIcon /> : <BriefcaseIcon />}
                    </IconButton>
                  </Tooltip>
                </>
              )
              : (
                <>
                  <Tooltip title="Refresh session">
                    <IconButton color="secondary">
                      <IssueReopenedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>
              <div className={itemsClass.root}>
                {row.appIds.map((items) => (
                  <InventoryItem
                    iconUrl={items.icon}
                    price={items.price}
                    marketName={items.market_name}
                    amount={items.amount}
                    appid={items.appid}
                    assetid={items.assetid}
                    contextid={items.contextid}
                    size="sm"
                  />
                ))}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const rows = [
  createData('53545345335', '53453453452', 'Hello', '34', '54354353', 50, true, false, 20, 40, '34', [], '32423'),
  createData('53545345335', '53453453452', 'Hello', '34', '54354353', 50, true, true, 20, 40, '34', [], '32423'),
];

const SessionList:React.FC = () => {
  const s = '';
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell align="right">Owner</TableCell>
            <TableCell align="right">Bot</TableCell>
            <TableCell align="right">Min price</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Inventory amount</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.steamId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SessionList;
