import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  ArrowUpIcon, InboxIcon, ShieldCheckIcon, ShieldIcon, ShieldXIcon,
} from '@primer/octicons-react';
import {
  Avatar, Badge, CircularProgress, Link, TablePagination,
} from '@material-ui/core';
import InventoryItem from '../InventoryItem';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
const itemsStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});
const useUserStyles = makeStyles((theme: Theme) => (
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
));

interface ITradeOffer {
  username: string,
  avatar: string,
  steamId: string,
  botId: string,
  amount: number,
  spammerId: string,
  date: string,
  items: Array<any>,
  status: number,
}
const getStatusColor = (status: number) => {
  switch (status) {
    case 2:
      return '#5999FF';
    case 3:
      return '#8ED853';
    case 6:
      return '#FB7673';
    case 7:
      return '#D6324C';
    case 11:
      return '#ffaf0b';
    default:
      return '#ffaf0b';
  }
};

const checkStatus:React.FC<number> = (status: number) => {
  switch (status) {
    case 2:
      return <CircularProgress style={{ width: 15, height: 15 }} />;
    case 3:
      return <ShieldCheckIcon />;
    case 6:
      return <ShieldXIcon />;
    case 7:
      return <ShieldXIcon />;
    case 11:
      return <ShieldIcon />;
    default:
      return <ShieldIcon />;
  }
};

const createData = (
  username: string,
  avatar: string,
  steamId: string,
  botId: string,
  amount: number,
  spammerId: string,
  date: string,
  items: Array<any>,
  status: number,
) => ({
  username,
  avatar,
  steamId,
  botId,
  amount,
  spammerId,
  date,
  items,
  status,
});

function Row(props: { row: ITradeOffer }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const userdataClass = useUserStyles();
  const classes = useRowStyles();
  const itemsClass = itemsStyles();
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton style={{ color: getStatusColor(row.status) }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {checkStatus(row.status)}
          </IconButton>
        </TableCell>
        <TableCell>
          <div className={userdataClass.root}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            <Link href={row.steamId}>
              {row.username}
              <br />
              <small>{row.steamId}</small>
            </Link>
          </div>
        </TableCell>
        <TableCell>
          <Link href={row.botId}>
            {row.botId}
          </Link>
        </TableCell>
        <TableCell align="right">
          <strong>
            $
            {row.amount}
          </strong>
        </TableCell>
        <TableCell align="right"><Link href={row.spammerId}>{row.spammerId}</Link></TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" color="primary" size="small" onClick={() => setOpen(!open)}>
            {open ? <ArrowUpIcon />
              : (
                <Badge badgeContent={row.items.length} color="error">
                  <InboxIcon />
                </Badge>
              )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Items
              </Typography>
              <div className={itemsClass.root}>
                {row.items.map((items) => (
                  <InventoryItem
                    iconUrl={items.icon}
                    price={items.price}
                    marketName={items.market_name}
                    amount={items.amount}
                    appid={items.appid}
                    assetid={items.assetid}
                    contextid={items.contextid}
                    size="sm"
                  />
                ))}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const rows = [
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [
    {
      assetid: 18679426474.0,
      contextid: 2,
      appid: 730,
      amonut: 1,
      market_name: 'CS20 Case',
      icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFU0naHKIj9D7oTgl4LelaGnMuqIwDgFusR337HCpYmhiwzm8ktqMjv2INKLMlhprbp6CTE',
      price: 0.544,
    },
    {
      assetid: 18679293054.0,
      contextid: 2,
      appid: 730,
      amonut: 1,
      market_name: 'MAC-10 | Last Dive (Field-Tested)',
      icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou7umeldf0vL3fDxBvYyJgIiOqPv1IK_ukmJH7fp8j-3I4IG72Vfkrko_N2ilcI-XJAA8ZQ3V_1i9w7q-h5G6tM-dz3BivnR0syrfzgv3309sGQspjA',
      price: 2.112,
    },
    {
      assetid: 18679287950.0,
      contextid: 2,
      appid: 730,
      amonut: 1,
      market_name: 'Sawed-Off | Zander (Well-Worn)',
      icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopbuyLgNv1fX3cCx9_92hkYSEkfHLKbrfkm5Duvp8j-3I4IG7i1Ww-0JtYTzxLdfAdlM7Y1vZ-FW3xu261sLv6cnMyyNj6HMk5yuLygv330_NFAA2ng',
      price: 0.256,
    },
    {
      assetid: 18598258705.0,
      contextid: 2,
      appid: 730,
      amonut: 1,
      market_name: 'Glock-18 | Candy Apple (Factory New)',
      icon: '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxfwPz3YzhG09C_k4if2aajMeqJlzgF6ZF10r2RrNyg3Qzjrkptazj7IYaVdwE4NFHRqFHtk-fxxcjr1j3fJ1k',
      price: 3.296,
    },
  ], 2),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 3),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 11),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 7),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 7),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 7),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 7),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 7),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 6),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 6),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 6),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 6),
  createData('Frozen yoghurt', '159', '6.0', '603', 24, '4.0', '2020-01-05', [], 6),
];

export default function TradeList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Bot</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Spammer</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Items</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={row.steamId} row={row} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
