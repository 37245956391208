import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { IContextUser, IUser } from '../contexts/AuthContext';

const Axios = axios.create({ withCredentials: true });
const useAuth = ():IContextUser => {
  const [user, setUser] = useState<IUser>({
    login: '',
    roles: [],
    avatar: '',
    isAuthenticated: false,
    isActive: false,
  });
  const [isReady, setIsReady] = useState(false);
  const getSession = useCallback(async () => {
    const query = await Axios.post(`${process.env.REACT_APP_DOMAIN}/auth/getSession`);
    if (query.data.isAuthenticated) {
      return setUser({ ...query.data.user, isAuthenticated: query.data.isAuthenticated });
    }
    return false;
  }, []);
  const login = async (userData:any) => {
    const query = await Axios.post(`${process.env.REACT_APP_DOMAIN}/auth/auth`, userData);
    if (query.data.isAuthenticated) {
      await getSession();
    }
    return query.data;
  };
  const logout = () => {
    const s = '';
    return false;
  };
  useEffect(() => {
    getSession().finally(() => setIsReady(true));
  }, [getSession]);
  return {
    user, login, logout, isReady,
  };
};

export default useAuth;
