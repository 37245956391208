import React from 'react';

interface IUser {
  login: string;
  roles: Array<string>;
  avatar: string;
  isAuthenticated: boolean;
  isActive: boolean;
}

interface IContextUser {
  user: IUser,
  login: (userdata:any) => any;
  logout: () => void;
  isReady: boolean;
}

const AuthContext = React.createContext<IContextUser>({
  user: {
    login: '',
    roles: [],
    avatar: '',
    isAuthenticated: false,
    isActive: false,
  },
  login() {
    return false;
  },
  logout() {
    return false;
  },
  isReady: false,
});

export type { IUser, IContextUser };
export { AuthContext };
