import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NavLink, useLocation } from 'react-router-dom';

interface INavbarItem {
  icon: JSX.Element,
  text: string,
  path: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 26,
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
  active: {
    borderRadius: 26,
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 30px',
    margin: 5,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    animation: '$activeItem 300ms',
  },
  container: {
    width: '200px',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  '@keyframes activeItem': {
    '0%': {
      padding: '10px 0px',
      fontSize: '0',
    },
    '100%': {
      padding: '10px 30px',
      fontSize: '16px',
    },
  },
}));

const NavbarItem:React.FC<INavbarItem> = (
  {
    text,
    icon,
    path,
  }:INavbarItem,
) => {
  const [active, setActive] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === path) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);
  const classes = useStyles();
  return (
    <NavLink to={path} className={classes.container} exact>
      <div className={active ? classes.active : classes.root}>
        <span style={{ marginRight: 10 }}>{icon}</span>
        {active && <span>{text}</span> }
      </div>
    </NavLink>
  );
};

export type { INavbarItem };
export default NavbarItem;
