import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { SearchIcon } from '@primer/octicons-react';
import DefaultPage from '../DefaultPage';
import TradeList from '../../components/TradeList';
import PageTitle from '../../components/PageTitle';

const TradesPage:React.FC = () => {
  const s = '';
  return (
    <div>
      <DefaultPage>
        <PageTitle title="Trade list">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField size="small" id="outlined-basic" label="Search SteamID" variant="outlined" />
            <Button>
              <SearchIcon />
            </Button>
          </div>
        </PageTitle>
        <TradeList />
      </DefaultPage>
    </div>
  );
};

export default TradesPage;
