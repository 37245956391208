import React, { useState } from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IInventoryItem {
  price: number,
  hold?: Date,
  marketName: string,
  iconUrl: string,
  contextid: number,
  assetid: number,
  appid: number,
  amount: number,
  selected?: boolean,
  size?: string,
}
const sizeMap:any = {
  sm: '70px',
  md: '120px',
  lg: '200px',
};

const sizeMapParse = (size:any) => (sizeMap[size.size.size]);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'auto',
    width: (size) => (sizeMapParse({ size })),
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    margin: '5px',
    flexFlow: 'column',
    alignItems: 'center',
    '& img': {
      margin: '10px',
      maxWidth: '100%',
    },
  },
}));
const InventoryItem:React.FC<IInventoryItem> = (
  {
    price,
    hold,
    marketName,
    assetid,
    amount,
    contextid,
    appid,
    iconUrl,
    selected = false,
    size = 'sm',
  }:IInventoryItem,
) => {
  const classes = useStyles({ size });
  const [select, setSelect] = useState<boolean>(selected);
  return (
    <Tooltip title={marketName}>
      <div className={classes.root}>
        <img src={`https://steamcommunity-a.akamaihd.net/economy/image/${iconUrl}`} alt="" />
        <Chip variant="outlined" size="small" label={`$ ${price.toFixed(2)}`} />
      </div>
    </Tooltip>
  );
};

export default InventoryItem;
